import * as React from "react";

function SvgAbout(props) {
  return (
    <svg
      data-name="Layer 1"
      width="1em"
      height="1em"
      viewBox="0 0 888.516 743.976"
      aria-labelledby="aboutImgTitle"
      role="img"
      {...props}
    >
      <title id="aboutImgTitle">
        Um prédio com dois carros a frente e um balão de check em cada carro.
      </title>
      <circle cx={249.509} cy={102.717} r={65.617} fill="#ff6584" />
      <path fill="#3f3d56" d="M632.671 121.848h-78.708V50.296l78.708 71.552z" />
      <path fill="#e6e6e6" d="M245.916 119.506h395.951v547.029H245.916z" />
      <path
        fill="#fff"
        d="M285.419 157.86h46.582v98.156h-46.582zM357.472 157.86h46.582v98.156h-46.582zM279.28 296.983h46.582v98.156H279.28zM357.472 296.983h46.582v98.156h-46.582zM279.28 425.709h46.582v98.156H279.28zM355.718 423.955H402.3v98.156h-46.582z"
      />
      <path fill="#ccc" d="M423.423 568.599h46.582v98.156h-46.582z" />
      <path
        fill="#fff"
        d="M493.802 423.955h46.582v98.156h-46.582zM571.994 423.955h46.582v98.156h-46.582zM495.556 295.944h46.582V394.1h-46.582zM573.748 295.944h46.582V394.1h-46.582zM495.556 157.86h46.582v98.156h-46.582zM573.748 157.86h46.582v98.156h-46.582z"
      />
      <path
        fill="#3f3d56"
        d="M245.916 245.944h395.951v14.973H245.916zM245.916 362.4h395.951v8.318H245.916zM245.916 335.781h395.951v8.318H245.916zM285.013 463.883v66.546h-8.318v-66.546zM331.595 463.883v66.546h-8.318v-66.546zM378.178 463.883v66.546h-8.318v-66.546zM424.76 463.883v66.546h-8.318v-66.546zM471.343 463.883v66.546h-8.318v-66.546zM517.925 463.883v66.546h-8.318v-66.546zM564.508 463.883v66.546h-8.318v-66.546zM611.09 463.883v66.546h-8.318v-66.546z"
      />
      <path
        fill="#3f3d56"
        d="M285.844 337.445v66.546h-8.318v-66.546zM332.427 337.445v66.546h-8.318v-66.546zM379.01 337.445v66.546h-8.319v-66.546zM425.592 337.445v66.546h-8.318v-66.546zM472.174 337.445v66.546h-8.318v-66.546zM518.757 337.445v66.546h-8.318v-66.546zM565.34 337.445v66.546h-8.319v-66.546zM611.922 337.445v66.546h-8.318v-66.546zM285.845 189.38v66.546h-8.318V189.38zM332.427 189.379v66.546h-8.318v-66.546zM379.01 189.38v66.546h-8.318V189.38zM425.592 189.379v66.546h-8.318V189.38zM472.175 189.38v66.546h-8.318V189.38zM518.757 189.38v66.545h-8.318V189.38zM565.34 189.38v66.546h-8.318V189.38zM611.922 189.38v66.545h-8.318V189.38z"
      />
      <path
        fill="#3f3d56"
        d="M245.916 212.671h395.951v8.318H245.916zM245.916 186.052h395.951v8.318H245.916zM245.916 390.682h395.951v14.973H245.916zM227.696 113.351h432.392v22.312H227.696z"
      />
      <path
        fill="#fff"
        d="M332.09 589.824v-21.225h-25.47v21.225H332.09zM329.967 606.803H306.62v21.225h25.47v-21.225h-2.123zM349.07 568.599h25.47v21.225h-25.47zM349.07 606.803h25.47v21.225h-25.47zM539.823 589.824v-21.225h-25.47v21.225H539.823zM537.7 606.803h-23.347v21.225h25.47v-21.225H537.7zM556.802 568.599h25.47v21.225h-25.47zM556.802 606.803h25.47v21.225h-25.47z"
      />
      <path
        fill="#3f3d56"
        d="M245.916 517.12h395.951v14.973H245.916zM245.916 490.502h395.951v8.318H245.916zM245.916 463.883h395.951v8.318H245.916z"
      />
      <path
        d="M365.788 659.405q0-.695-.02-1.382c-.355-12.264-12.52-15.59-19.043-9.785a11.314 11.314 0 00-14.836 0c-6.55-5.714-18.473-2.624-18.867 9.5q-.022.688-.023 1.382a71.13 71.13 0 00.468 7.63h51.859a70.394 70.394 0 00.462-7.345zM571.897 659.405q.002-.695-.019-1.382c-.355-12.264-12.521-15.59-19.043-9.785a11.314 11.314 0 00-14.836 0c-6.55-5.714-18.473-2.624-18.867 9.5q-.022.688-.024 1.382a71.133 71.133 0 00.468 7.63h51.86a70.394 70.394 0 00.461-7.345z"
        fill="currentColor"
      />
      <path
        fill="#e6e6e6"
        d="M856.314 667.579h-8.02l2.673-156.668h2.674l2.673 156.668z"
      />
      <path
        d="M862.997 412.277l12.947-20.008a299.962 299.962 0 00-13.283-31.093l-8.371 6.762 6.628-10.244c-6.317-12.436-11.287-20.27-11.287-20.27s-25.965 40.916-34.64 84.236l16.614 25.675-18.392-14.854a121.357 121.357 0 00-1.009 15.217c0 51.306 16.756 92.898 37.427 92.898s37.426-41.592 37.426-92.898c0-15.905-3.598-32.546-8.562-47.939z"
        fill="#e6e6e6"
      />
      <path
        fill="currentColor"
        d="M828.074 448.447H656.97v125.986h.104v25.083l25.083-25.083h145.917V448.447z"
      />
      <path
        fill="#e6e6e6"
        d="M459.892 717.512h151.803v6H459.892zM735.892 717.512h152.624v6H735.892zM183.892 717.512h152v6h-152z"
      />
      <path
        fill="#3f3d56"
        d="M0 666.619h887.892v1.673H0zM714.269 695.521l-17.876-.01.001-2.837 17.876.01z"
      />
      <path
        d="M567.15 693.314l3.688.854 140.169.075 1.26-2.937a29.642 29.642 0 002.18-16.028c-.604-4.056-2.117-8.086-5.553-9.836l-6.22-41.43-77.548-.139-30.003 24.2s-14.153-.255-21.842 9.317a22.468 22.468 0 00-4.7 12.375l-.303 4.98z"
        fill="currentColor"
      />
      <circle cx={597.936} cy={692.621} r={17.308} fill="#3f3d56" />
      <circle cx={597.936} cy={692.621} r={9.11} fill="#ccc" />
      <circle cx={683.343} cy={692.667} r={17.308} fill="#3f3d56" />
      <circle cx={683.343} cy={692.667} r={9.11} fill="#ccc" />
      <path
        fill="#fff"
        d="M607.043 645.244l41.87.018h5.111l8.629.006v-.63l.006-7.587.006-8.521h-5.74l-5.112-.006-9.053-.006h-5.111l-10.452-.006-20.154 16.732zM669.466 645.274l22.128.012.006-4.342v-7.588l.006-4.808h-3.24l-5.111-.006-13.783-.006-.006 16.738z"
      />
      <path
        fill="#3f3d56"
        d="M662.642 663.15h-2.837l.003-5.392 2.837.002zM618.392 650.074l-.002 2.837-5.39-.003v-2.837z"
      />
      <path
        d="M616.402 651.318h-.312a5.93 5.93 0 01-5.927-5.933 5.93 5.93 0 015.933-5.927h.312l-.006 11.86zM568.278 674.744a9.166 9.166 0 005.003-17.354 22.468 22.468 0 00-4.7 12.375z"
        fill="#3f3d56"
      />
      <path
        fill="#f2f2f2"
        d="M651.813 628.524l10.84 16.114.006-7.587-5.734-8.521-5.112-.006zM683.255 628.542l8.345 12.402v-7.588l-3.234-4.808-5.111-.006zM637.649 628.518l11.264 16.744h5.111l-11.264-16.744h-5.111z"
      />
      <path
        d="M410.397 1.048l-.116.063a8.551 8.551 0 01-8.153.029l-.216-.116a8.592 8.592 0 00-8.144.028l-.108.06a8.551 8.551 0 01-8.153.028l-.224-.12a8.543 8.543 0 00-8.064-.014c-3.793 2.042-8.92 5.534-8.897 9.477a4.587 4.587 0 00.026.467c.441 4.096 13.8 5.14 21.137 3.21a37.358 37.358 0 0016.621 0c7.307 1.96 20.936.837 21.335-3.306a4.588 4.588 0 00.021-.467c-.023-3.945-5.195-7.4-9.011-9.414a8.535 8.535 0 00-8.054.075zM778.397 136.264l-.116.063a8.551 8.551 0 01-8.153.029l-.216-.116a8.592 8.592 0 00-8.144.028l-.108.06a8.551 8.551 0 01-8.153.028l-.224-.12a8.543 8.543 0 00-8.064-.014c-3.793 2.042-8.92 5.534-8.897 9.477a4.587 4.587 0 00.026.467c.441 4.096 13.8 5.14 21.137 3.209a37.358 37.358 0 0016.621 0c7.307 1.961 20.936.838 21.335-3.305a4.588 4.588 0 00.02-.467c-.022-3.946-5.194-7.4-9.01-9.414a8.535 8.535 0 00-8.054.075zM133.967 143.812l-.116.063a8.551 8.551 0 01-8.153.029l-.215-.116a8.592 8.592 0 00-8.145.028l-.108.06a8.551 8.551 0 01-8.153.028l-.224-.12a8.543 8.543 0 00-8.064-.014c-3.793 2.042-8.92 5.534-8.897 9.477a4.587 4.587 0 00.026.467c.441 4.096 13.8 5.14 21.137 3.21a37.358 37.358 0 0016.621 0c7.307 1.96 20.936.837 21.335-3.306a4.591 4.591 0 00.021-.467c-.023-3.945-5.195-7.4-9.01-9.414a8.535 8.535 0 00-8.055.075z"
        fill="#e6e6e6"
      />
      <path fill="#3f3d56" d="M92.278 726.679h17.876v2.837H92.278z" />
      <path
        d="M239.398 727.315l-3.688.853-140.168.075-1.26-2.937a29.642 29.642 0 01-2.18-16.028c.604-4.055 2.116-8.086 5.552-9.836l6.22-41.43 77.548-.138 30.003 24.199s14.153-.254 21.842 9.318a22.468 22.468 0 014.7 12.374l.303 4.98z"
        fill="currentColor"
      />
      <circle cx={208.612} cy={726.622} r={17.308} fill="#3f3d56" />
      <circle cx={208.612} cy={726.622} r={9.11} fill="#ccc" />
      <circle cx={123.206} cy={726.668} r={17.308} fill="#3f3d56" />
      <circle cx={123.206} cy={726.668} r={9.11} fill="#ccc" />
      <path
        fill="#fff"
        d="M199.505 679.244l-41.87.019h-5.111l-8.629.006v-.63l-.006-7.588-.006-8.52h5.741l5.111-.007 9.053-.006h5.111l10.452-.006 20.154 16.732zM137.082 679.275l-22.128.012-.006-4.342v-7.588l-.006-4.808h3.24l5.111-.006 13.783-.006.006 16.738z"
      />
      <path
        fill="#3f3d56"
        d="M143.901 691.756h2.837v5.391h-2.837zM193.551 684.069l.002 2.837-5.391.003-.002-2.837z"
      />
      <path
        d="M190.143 673.457h.312a5.93 5.93 0 015.93 5.93 5.93 5.93 0 01-5.93 5.93h-.312v-11.86zM238.27 708.745a9.166 9.166 0 01-5.003-17.354 22.468 22.468 0 014.7 12.374z"
        fill="#3f3d56"
      />
      <path
        fill="#f2f2f2"
        d="M154.735 662.524l-10.84 16.115-.006-7.588 5.735-8.52 5.111-.007zM123.293 662.543l-8.345 12.402v-7.588l3.234-4.808 5.111-.006zM168.899 662.518l-11.264 16.745h-5.111l11.264-16.745h5.111z"
      />
      <path
        fill="#fff"
        d="M736.528 538.291l-21.85-28.092 12.706-9.883 10.345 13.301 34.95-36.892 11.687 11.072-47.838 50.494z"
      />
      <path
        fill="currentColor"
        d="M6.34 480.325h171.104v125.986h-.104v25.083l-25.083-25.083H6.34V480.325z"
      />
      <path
        fill="#fff"
        d="M87.042 573.169l-21.85-28.092 12.707-9.884 10.345 13.302 34.949-36.892 11.687 11.071-47.838 50.495z"
      />
    </svg>
  );
}

export default SvgAbout;
